'use strict';

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

const angular = require('angular');
const angularTranslate = require('angular-translate');
const angularTranslateStorageCookie = require('angular-translate-storage-cookie');
const angularTranslateStorageLocal = require('angular-translate-storage-local');
const angularTranslateLoaderPartial = require('angular-translate-loader-partial');
const angularTranslateLoaderStaticFiles = require('angular-translate-loader-static-files');
const ngResource = require('angular-resource');
const freshideasResourcesLookup = require('./common/resources/lookup.js').default;
const freshideasServicesPosStatus = require('./common/services/pos-status-service.js').default;

const Honeybadger = require('honeybadger-js');

require('script-loader!./app-config.js').default;

var freshideasLogin = angular.module('freshideasLogin', [
    'app.config',
    angularTranslate,
    angularTranslateStorageCookie,
    angularTranslateStorageLocal,
    angularTranslateLoaderPartial,
    angularTranslateLoaderStaticFiles,
    ngResource,
    freshideasResourcesLookup.name,
    freshideasServicesPosStatus.name,
    'NownPlatform',
    'KioskBoard'
]);

import {nownKioskBoard} from './common/components/kioskboard/nownkioskboard.js';
nownKioskBoard(angular, freshideasLogin);

import {platformService} from './common/services/platform.service.js';
platformService(angular);

freshideasLogin.config([
    '$translateProvider',
    '$translatePartialLoaderProvider',
    function (
        $translateProvider,
        $translatePartialLoaderProvider
    ) {
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: '/{part}/i18n/{lang}.json'
        });
        $translateProvider.fallbackLanguage('en-US');
        $translateProvider.preferredLanguage('en-US');
        $translateProvider.useSanitizeValueStrategy('escape');
        $translatePartialLoaderProvider.addPart('common');
        $translateProvider.forceAsyncReload(true);
    }
]);

// this is so the mfaCode param can be dynamically updated
let MFA_CODE = null;
const getMFACode = () => {
    return MFA_CODE;
};

freshideasLogin.service('LoginService', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
    var LoginService = $resource(EnvConfig.host + '/freshideas/web/users/meSession', {}, {
        'login': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me', params: {ignoreAuthModule: true, mfaCode: getMFACode}},
        'changePassword': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/changePasswordByToken'},
        'forgotPassword': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/forgotPassword'}, // params: {ignoreAuthModule: true}},
        'logout': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/logout'},
        'getUser': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me'},
        'getWithToken': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/meToken'},
        'seenTutorial': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/seenTutorial'},
        'me': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me'}
    });
    return LoginService;
}]);

freshideasLogin.controller('LoginController', [
    '$scope',
    '$http',
    '$location',
    'LoginService',
    'PosStatusService',
    'Lookup',
    function (
        $scope,
        $http,
        $location,
        LoginService,
        PosStatusService,
        Lookup
        ) {
        var init = async function () {
            await LoginService.getUser({}, function (response) {
                location.href = location.protocol + '//' + location.host + '/app/' + location.hash;
            }, function (error) {
                setTimeout(function () {
                    $('.body-loading').fadeOut(500).delay(500).hide();
                    $('.body-inner').show();
                }, 0);
            });
        };

        init();

        $scope.$watch(function () {
            return PosStatusService.isOffline();
        }, function (value) {
            $scope.isOffline = value;
        });

        const updateOfflineStatus = function () {
            Lookup.pingPong({}, function () {
                PosStatusService.setOffline(false);
            }, function (error) {
                /* For now we must let the POS go into offline mode even if it's our server
                that's down and not our client's connection, or they won't be able to use
                Offline Mode if our server goes down! */
                PosStatusService.setOffline(true);
            });
        };

        $scope.MODAL = {
            'LOGIN': 'login',
            'DOWNLOAD_PROMPT': 'downloadPrompt',
            'PASSWORD_CHANGE': 'passwordChange',
            'FORGOT_PASSWORD': 'forgotPassword'
        };

        let parameters = $location.search();
        let passwordToken, downloadPrompt;
        if (parameters) {
            passwordToken = parameters.token;
            downloadPrompt = parameters.downloadPrompt;
        }

        $scope.modal = $scope.MODAL.LOGIN; // default modal
        $scope.inputFocused = false;
        $scope.passwordToken = passwordToken;
        $scope.user = {password: ''};
        $scope.changePasswordDetails = {
            token: '',
            password: '',
            confirmPassword: ''
        };

        var onLoad = function () {
            if (angular.isDefined(passwordToken)) {
                $scope.modal = 'passwordChange';
            }
        };

        onLoad();
        $scope.useOfflineMode = function () {
            location.href = location.protocol + '//' + location.host + '/app/' + location.hash;
        };

        $scope.mfa = {};
        $scope.isLoggingIn = false;
        $scope.login = function () {
            updateOfflineStatus();
            $scope.mfa.emailSent = false;
            if ($scope.mfa.show && !$scope.mfa.code) {
                $scope.authError = 'login.error.mfa.invalid';
                return;
            }

            if ($scope.mfa.code) {
                MFA_CODE = $scope.mfa.code;
            }

            localStorage.username = $scope.user.username;
            Honeybadger.setContext({
                username: $scope.user.username
            });
            $http.defaults.headers.common.Authorization = 'Basic ' + btoa($scope.user.username + ':' + $scope.user.password);

            $scope.isLoggingIn = true;
            LoginService.login($scope.user.username, $scope.user.password).$promise.then(function () {
                // Doing this so that a hard reload happens upon login
                location.reload(true);

                // location.href = location.protocol + '//' + location.host + '/app/' + location.hash;
            }, function (error) {
                $scope.isLoggingIn = false;
                if (error && error.data) {
                    const exception = error.data.exception || {};
                    if (exception.appCode === 614 && !$scope.mfa.show) { // 614 => verification code required (not provided)
                        $scope.mfa.show = true;
                        $scope.authError = null;
                        $scope.mfa.emailSent = true;
                    } else if (exception.appCode === 615) { // 615 => unable to send MFA code to email
                        $scope.authError = 'login.error.mfa.email';
                    } else if (exception.appCode === 608) { // 608 => invalid MFA code provided
                        $scope.authError = 'login.error.mfa.invalid';
                    } else {
                        $scope.authError = 'login.error';
                    }
                } else {
                    $scope.authError = 'login.error';
                }
            });
        };

        $scope.clearMFA = () => {
            $scope.mfa.show = false;
            $scope.mfa.code = null;
            MFA_CODE = null;
        };

        $scope.toggleLogin = function (toggle) {
            if (toggle) {
                $scope.modal = 'forgotPassword';
            } else {
                $scope.modal = 'login';
            }
        };
        $scope.toggleFocus = function (toggle) {
            $scope.inputFocused = toggle;
        };
        $scope.forgotPassword = function () {
            LoginService.forgotPassword({}, {'username': $scope.user.username}).$promise.then(function () {
                $scope.forgottenPassword = false;
                $scope.user.username = '';
                $scope.forgotPasswordSent = 'Successfully requested forgotten password.';
            }, function () {
            });
        };
        $scope.changePassword = function () {
            $scope.authError = undefined;
            $scope.changePasswordDetails.token = $scope.passwordToken;
            LoginService.changePassword({}, $scope.changePasswordDetails, function (response) {
                $scope.passwordToken = undefined;

                if (downloadPrompt) {
                    $scope.username = response.username;
                    $scope.modal = $scope.MODAL.DOWNLOAD_PROMPT;
                } else {
                    // log the user in
                    $scope.user = {
                        username: response.username,
                        password: $scope.changePasswordDetails.password
                    };

                    $scope.login();
                }
            }, function (error) {
                $scope.authError = error.data.error;
            });
        };
    }
]);
